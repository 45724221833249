import * as React from 'react'
import styled from '@emotion/styled'

import Option from './Option'
import state, { State } from '../state'

const Options = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14pt;
`

export const OptionPanel = (props: State) => (
  <Options>
    <Option
      label="Initial investment"
      value={props.initialInvestment}
      onChange={n => {
        state.initialInvestment = n
      }}
    />
    <Option
      label="Monthly investment"
      value={props.monthlyInvestment}
      onChange={n => {
        state.monthlyInvestment = n
      }}
    />
    <Option
      label="Years"
      value={props.years}
      onChange={n => {
        state.years = n
      }}
    />
    <Option
      label="Years to retirement"
      value={props.retirementYears}
      onChange={n => {
        state.retirementYears = n
      }}
    />
    <Option
      label="Monthly expenses"
      value={props.expenses}
      onChange={n => {
        state.expenses = n
      }}
    />
  </Options>
)

export default OptionPanel
