import * as React from 'react'
import state, { Investment } from '../state'
import styled from '@emotion/styled'
import { rerender } from '../render'

const SummaryWrapper = styled.tr``

const Column = styled.td`
  padding: 1em;
  border-bottom: 1px solid lightgrey;
`

export const InvestmentSummary = ({
  investment,
  index,
}: {
  investment: Investment
  index: number
}) => (
  <SummaryWrapper
  /*onClick={() => {
      state.selected = index
      state.modalOpen = true
      rerender()
    }}*/
  >
    <Column style={{ textAlign: 'left' }}>{investment.label}</Column>
    <Column style={{ textAlign: 'right' }}>
      {(investment.returns * 100).toFixed(2)}%
    </Column>
    <Column style={{ textAlign: 'right' }}>
      {(investment.TER * 100).toFixed(2)}%
    </Column>
    <Column style={{ textAlign: 'right' }}>
      {(investment.brokerageFee * 100).toFixed(2)}%
    </Column>
    <Column style={{ textAlign: 'right' }}>Edit</Column>
  </SummaryWrapper>
)

export default InvestmentSummary
