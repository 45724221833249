import styled from '@emotion/styled'

export const Input = styled.input`
  transition: 100ms;
  border: none;
  border-bottom: 2px solid lightgray;
  font-size: 1em;
  padding: 0.5em;
  outline: none;
  text-align: right;
  border-radius: 0;

  :focus {
    border-color: #00e6c5;
  }
`

export default Input
