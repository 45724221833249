const tax =
  (baseRate: number, baseMax: number, additionalRate: number) =>
  (taxed: number, amount: number) => {
    const base = Math.max(0, baseMax - taxed);
    let taxable = 0;
    // Loss
    if (amount < 0) {
      taxable += baseRate * Math.max(-base, amount);
      if (Math.abs(amount) > base) {
        taxable += additionalRate * (amount + base);
      }
    }
    // Gain
    if (amount > 0) {
      taxable += baseRate * Math.min(base, amount);
      if (amount > base) {
        taxable += additionalRate * (amount - base);
      }
    }
    return taxable;
  };

export const simpleTax = (rate: number) => (taxed: number, amount: number) => {
  return amount * rate;
};

export const stockTax = tax(27 / 100, 55000, 42 / 100);

export const capitalStockTax = tax(38 / 100, 44.8, 42 / 100);

export const palTax = simpleTax(15.3 / 100);

export const stockSavingsTax = simpleTax(17 / 100);
