import * as React from 'react'
import { Investment } from '../state'
import InvestmentSummary from './InvestmentSummary'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
`

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`

const Column = styled.th`
  padding: 1em;
  border-bottom: 1px solid lightgray;
`

export const Investments = ({ investments }: { investments: Investment[] }) => (
  <Wrapper>
    <Table>
      <tbody>
        <tr>
          <Column style={{ textAlign: 'left' }}>Investment</Column>
          <Column style={{ textAlign: 'right' }}>Returns</Column>
          <Column style={{ textAlign: 'right' }}>Fees</Column>
          <Column style={{ textAlign: 'right' }}>Brokerage</Column>
          <Column style={{ textAlign: 'right' }}>Edit</Column>
        </tr>
        {investments.map((investment, i) => (
          <InvestmentSummary investment={investment} index={i} />
        ))}
      </tbody>
    </Table>
  </Wrapper>
)

export default Investments
