import * as React from "react";
import { Line } from "react-chartjs-2";
import { ChartTooltipItem } from "chart.js";

type Props = {
  months: string[];
  values: {
    label: string;
    data: number[];
  }[];
};

export const Chart = (props: Props) => (
  <div>
    <Line
      options={{
        hover: {
          mode: "index",
          intersect: false,
        },
        tooltips: {
          mode: "index",
          intersect: false,
          itemSort: (a: ChartTooltipItem, b: ChartTooltipItem) => a.y - b.y,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      }}
      data={{
        labels: props.months,
        datasets: props.values.map((value) => ({
          label: value.label,
          data: value.data,
          pointRadius: 0,
        })),
      }}
    />
  </div>
);

export default Chart;
