export enum TaxType {
  None,
  Realization,
  Stock,
  CapitalStock,
  StockSavings,
  PAL,
}

export type Investment = {
  label: string;

  // Returns
  returns: number;
  maxReturns: number;

  // Dividends
  dividendYield: number;
  dividendMonths: number[];

  // Fees
  TER: number;
  brokerageFee: number;
  brokerageMin: number;
  currencyConversionFee: number;

  // Tax
  taxType: TaxType;
};

export type State = {
  modalOpen: boolean;
  selected: number;

  initialInvestment: number;
  monthlyInvestment: number;
  years: number;
  retirementYears: number;
  expenses: number;
  investments: Investment[];
};

export const state: State = /*JSON.parse(localStorage.getItem('state')) ||*/ {
  modalOpen: false,
  selected: 0,

  initialInvestment: 0,
  monthlyInvestment: 10000,
  expenses: 10000,
  years: 75,
  retirementYears: 15,
  investments: [
    {
      label: "Checking",
      TER: 0,
      returns: 0,
      maxReturns: 0,
      dividendYield: 0,
      dividendMonths: [],
      brokerageFee: 0,
      brokerageMin: 0,
      currencyConversionFee: 0,
      taxType: TaxType.None,
    },
    {
      label: "Savings",
      TER: 0,
      returns: 0.6 / 100,
      maxReturns: 0,
      dividendYield: 0,
      dividendMonths: [],
      brokerageFee: 0,
      brokerageMin: 0,
      currencyConversionFee: 0,
      taxType: TaxType.None,
    },
    {
      label: "High return savings",
      TER: 0,
      returns: 2 / 100,
      maxReturns: 0,
      dividendYield: 0,
      dividendMonths: [],
      brokerageFee: 0,
      brokerageMin: 0,
      currencyConversionFee: 0,
      taxType: TaxType.None,
    },
    {
      label: "ETF",
      TER: 0.4 / 100,
      returns: 5 / 100,
      maxReturns: 0,
      dividendYield: 0 / 100,
      dividendMonths: [],
      brokerageFee: 0.1 / 100,
      brokerageMin: 0,
      currencyConversionFee: 0.25 / 100,
      taxType: TaxType.CapitalStock,
    },
    {
      label: "ETF 2020",
      TER: 0.4 / 100,
      returns: 5 / 100,
      maxReturns: 0,
      dividendYield: 0 / 100,
      dividendMonths: [],
      brokerageFee: 0.1 / 100,
      brokerageMin: 0,
      currencyConversionFee: 0.25 / 100,
      taxType: TaxType.Stock,
    },
    {
      label: "Fund",
      TER: 0.5 / 100,
      returns: 5 / 100,
      maxReturns: 0,
      dividendYield: 2 / 100,
      dividendMonths: [2],
      brokerageFee: 0.1 / 100,
      brokerageMin: 0,
      currencyConversionFee: 0,
      taxType: TaxType.Realization,
    },
    /*{
      label: 'StockSavingsAccount fund',
      TER: 0 / 100,
      returns: 5 / 100,
      maxReturns: 0,
      dividendYield: 2 / 100,
      dividendMonths: [2],
      brokerageFee: 0.1 / 100,
      brokerageMin: 0,
      currencyConversionFee: 0.0,
      taxType: TaxType.StockSavings,
    },
    {
      label: 'Superfonden',
      TER: 0 / 100,
      returns: 5 / 100,
      maxReturns: 0,
      dividendYield: 0 / 100,
      dividendMonths: [],
      brokerageFee: 0.0 / 100,
      brokerageMin: 0,
      currencyConversionFee: 0.0,
      taxType: TaxType.PAL,
    },*/
  ],
};

export default state;
