import * as React from 'react'
import styled from '@emotion/styled'

import Input from './Input'

import { state, State } from '../state'
import { rerender } from '../render'

const OptionWrapper = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 1em;
  margin-bottom: 14pt;
`

type Props = {
  label: string
  value: number
  onChange: (n: number) => void
}

export const Option = (props: Props) => (
  <OptionWrapper>
    {props.label}
    <Input
      type="number"
      value={props.value}
      onChange={e => {
        if (!Number.isNaN(parseFloat(e.target.value))) {
          props.onChange(parseFloat(e.target.value))
          localStorage.setItem('state', JSON.stringify(state))
        }
        rerender()
      }}
    />
  </OptionWrapper>
)

export default Option
