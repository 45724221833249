import * as React from 'react'
import styled from '@emotion/styled'
import { rerender } from '../render'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
`

const BackDrop = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  pointer-events: none;
`

const ModalContainer = styled.div`
  position: absolute;
  background: #fff;
  padding: 1em;
  margin: 1em;
  width: calc(100% - 2em);
  max-height: calc(100% - 2em);
  display: flex;
  flex-direction: column;
`

const ModalHeader = styled.h1`
  margin: 0;
  margin-bottom: 1rem;
  height: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ModalBody = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`

const Cross = styled.div`
  cursor: pointer;
  color: gray;
`

type Props = {
  onClose: () => void
  header: string
}

export const Modal = (props: React.PropsWithChildren<Props>) => (
  <Wrapper>
    <BackDrop />
    <ModalContainer>
      <ModalHeader>
        {props.header}
        <Cross
          onClick={() => {
            props.onClose()
            rerender()
          }}
        >
          X
        </Cross>
      </ModalHeader>
      <ModalBody>{props.children}</ModalBody>
    </ModalContainer>
  </Wrapper>
)

export default Modal
