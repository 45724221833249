import * as React from 'react'
import Modal from './Modal'
import { Investment } from '../state'

type Props = {
  onClose: () => void
  investment: Investment
}

export const InvestmentModal = (props: Props) => (
  <Modal onClose={props.onClose} header="Investment">
    {props.investment.label}
  </Modal>
)

export default InvestmentModal
