import { State, TaxType } from "../state";
import { upfrontFee, percentFee } from "./fees";
import { stockTax, stockSavingsTax } from "./tax";

export const simulateStockTax =
  (tax: (taxed: number, amount: number) => number) =>
  (s: State, index: number) => {
    const investment = s.investments[index];

    let values = [];
    let value = s.initialInvestment;
    let account = 0;

    let taxed = 0;

    let valueLastYear = s.initialInvestment;
    let investedPastYear = 0;

    const buyFee = upfrontFee(
      investment.brokerageFee,
      investment.brokerageMin,
      investment.currencyConversionFee
    );

    for (let i = 0; i < s.years * 12; i++) {
      // Monthly payments
      if (i < s.retirementYears * 12) {
        const investable = s.monthlyInvestment + account;
        if (investable - buyFee(investable) > 0) {
          value += investable - buyFee(investable);
          investedPastYear += investable - buyFee(investable);
          account = 0;
        } else {
          account += investable;
        }
      } else {
        value -= s.expenses;
        value -= buyFee(s.expenses);

        valueLastYear -= s.expenses;
        valueLastYear -= buyFee(s.expenses);
      }

      // Fees
      value -= percentFee(value, investment.TER / 12);

      // Dividends
      if (investment.dividendMonths.indexOf(i % 12) !== -1) {
        const yearlyDividends = investment.dividendMonths.length;
        const dividendYield = investment.dividendYield / yearlyDividends;
        const amount = dividendYield * value;
        value -= amount;
        account += amount - tax(taxed, amount);
        taxed += amount;
      }

      // Returns
      value += percentFee(value, investment.returns / 12);

      // Tax
      if (i % 12 == 11) {
        const taxable = value - valueLastYear - investedPastYear;
        value -= tax(taxed, taxable);

        taxed = 0;
        investedPastYear = 0;
        valueLastYear = value;
      }

      // Ran out of money
      if (value < 0) {
        value = 0;
      }

      // Add value to history
      values.push(value);
    }

    return values;
  };

type Position = {
  buyPrice: number;
  amount: number;
};

export const simulateRealizationTax = (s: State, index: number) => {
  const investment = s.investments[index];
  let values: number[] = [];

  let price = 10;

  let acquired = 0;
  let avgPrice = 0;

  const buyFee = upfrontFee(
    investment.brokerageFee,
    investment.brokerageMin,
    investment.currencyConversionFee
  );

  const tax = stockTax;

  let account = s.initialInvestment;
  let taxed = 0;

  for (let i = 0; i < s.years * 12; i++) {
    // Price increase
    price += ((investment.returns - investment.TER) / 12) * price;

    // Add money to account
    if (i < s.retirementYears * 12) {
      account += s.monthlyInvestment;
    }

    // Buy with available
    const cash = account - buyFee(account);
    if (cash > 0) {
      const prevAcquired = acquired;
      acquired += cash / price;
      avgPrice =
        (avgPrice * prevAcquired + cash) / (prevAcquired + cash / price);
      account = 0;
    }

    // Dividends
    if (investment.dividendMonths.indexOf(i % 12) !== -1) {
      const yearlyDividends = investment.dividendMonths.length;
      const dividendYield = investment.dividendYield / yearlyDividends;
      const amount = acquired;

      const perStockDividend = dividendYield * price;
      price -= perStockDividend;
      const dividend = perStockDividend * amount;
      account += dividend - tax(taxed, dividend);
      taxed += dividend;
    }

    // Withdrawal
    if (i >= s.retirementYears * 12) {
      let expenses = s.expenses + buyFee(s.expenses);

      if (account > expenses) {
        account -= expenses;
        expenses = 0;
      } else {
        expenses -= account;
        account = 0;
      }

      while (expenses > 0.001 && acquired > 0) {
        const value = acquired * price;

        const sellAmount = expenses / price;
        const sellValue = sellAmount * price;
        const profit = sellAmount * (price / avgPrice);
        const taxExpenses = tax(taxed, profit);

        if (sellAmount > acquired) {
          acquired = 0;
          account = 0;
        } else {
          expenses -= sellValue - taxExpenses;
          taxed += profit;
          acquired -= sellAmount;
        }
      }
    }

    // End of year
    if (i % 12 === 11) {
      taxed = 0;
    }

    // Add current value of positions to values
    values.push(price * acquired + account);
  }

  console.log(values);

  return values;
};
