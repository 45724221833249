export const percentFee = (amount: number, rate: number) => rate * amount

export const brokerage = (rate: number, min: number) => (amount: number) =>
  Math.max(min, percentFee(amount, rate))

export const currencyConversion = (rate: number) => (amount: number) =>
  percentFee(amount, rate)

export const upfrontFee = (
  brokerageFee: number,
  brokerageMin: number,
  currencyConversionFee: number
) => (amount: number) =>
  brokerage(brokerageFee, brokerageMin)(
    currencyConversion(currencyConversionFee)(amount)
  )
