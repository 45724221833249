import * as React from 'react'
import Chart from './Chart'
import state, { State, TaxType } from '../state'
import { simulateStockTax, simulateRealizationTax } from '../simulation'
import styled from '@emotion/styled'
import OptionPanel from './OptionPanel'
import Investments from './Investements'
import InvestmentModal from './InvestmentModal'
import {
  stockTax,
  capitalStockTax,
  palTax,
  stockSavingsTax,
} from '../simulation/tax'

const ChartWrapper = styled.div`
  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 2fr 8fr;
  }
`

export const App = (props: State) => {
  const values = props.investments.map((investment, i) => ({
    data:
      investment.taxType === TaxType.Realization
        ? simulateRealizationTax(props, i)
        : investment.taxType === TaxType.CapitalStock
        ? simulateStockTax(capitalStockTax)(props, i)
        : investment.taxType === TaxType.PAL
        ? simulateStockTax(palTax)(props, i)
        : investment.taxType === TaxType.StockSavings
        ? simulateStockTax(stockSavingsTax)(props, i)
        : simulateStockTax(stockTax)(props, i),
    label: investment.label,
  }))

  const months = Array.from(new Array(props.years * 12), (_, i) => `${i + 1}`)

  return (
    <>
      <ChartWrapper>
        <OptionPanel {...props} />
        <Chart months={months} values={values} />
      </ChartWrapper>
      <Investments investments={props.investments} />
      {props.modalOpen && (
        <InvestmentModal
          investment={props.investments[state.selected]}
          onClose={() => {
            state.modalOpen = false
          }}
        />
      )}
    </>
  )
}

export default App
